import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about">
      <h1>About Sebatian Validator</h1>
      <p>Sebatian Validator is dedicated to providing secure and reliable staking services across multiple blockchain networks.</p>
      <p>Our mission is to ensure the integrity and security of decentralized networks while empowering our delegators with the best staking experience.</p>
      <p>With a commitment to transparency, performance, and community, Sebatian Validator is your trusted partner in the world of decentralized finance.</p>
    </div>
  );
};

export default About;
