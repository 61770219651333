import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaMoon, FaSun, FaBars } from 'react-icons/fa';
import './Header.css';

const Header = ({ toggleDarkMode, isDarkMode }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="header">
      <div className="logo-section">
        <img src="/sebatian-logo.png" alt="Sebatian Logo" className="logo" />
        <span className="logo-text">Sebatian Validator</span>
      </div>

      <nav className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <Link to="/" onClick={() => setMenuOpen(false)}>Home</Link>
        <Link to="/about" onClick={() => setMenuOpen(false)}>About</Link>
        <Link to="/projects" onClick={() => setMenuOpen(false)}>Projects</Link>
      </nav>

      <div className="menu-toggle">
        <button onClick={toggleDarkMode} className="dark-mode-toggle">
          {isDarkMode ? <FaSun /> : <FaMoon />}
        </button>
        <button onClick={() => setMenuOpen(!menuOpen)} className="menu-icon">
          <FaBars />
        </button>
      </div>
    </header>
  );
};

export default Header;
