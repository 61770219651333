import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <p className="footer-text">&copy; 2024 Sebatian Validator. All rights reserved.</p>
      <div className="social-icons">
        <a href="https://x.com/Sebatian9x" target="_blank" rel="noopener noreferrer">
          <img src="/icons/twitter.png" alt="Twitter" />
        </a>
        <a href="https://discord.com" target="_blank" rel="noopener noreferrer">
          <img src="/icons/discord.png" alt="Discord" />
        </a>
        <a href="https://t.me/SebatianDD" target="_blank" rel="noopener noreferrer">
          <img src="/icons/telegram.png" alt="Telegram" />
        </a>
        <a href="https://sebatian.org" target="_blank" rel="noopener noreferrer">
          <img src="/icons/website.png" alt="Website" />
        </a>
        <a href="https://github.com/Sebatian1" target="_blank" rel="noopener noreferrer">
          <img src="/icons/github.png" alt="GitHub" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
