import React from 'react';
import { useParams } from 'react-router-dom';

const Project = () => {
  const { projectName } = useParams();

  const projectDetails = {
    airchains: { /* Project info */ },
    initia: { /* Project info */ },
    // Add additional projects here
  };

  const project = projectDetails[projectName.toLowerCase()];

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <div>
      <img src={project.logo} alt={`${projectName} Logo`} className="project-logo" />
      <h1>{projectName.charAt(0).toUpperCase() + projectName.slice(1)}</h1>
      <ul>
        <li><a href={project.guide} target="_blank" rel="noopener noreferrer">Guide</a></li>
        <li><a href={project.explorer} target="_blank" rel="noopener noreferrer">Explorer</a></li>
        <li><a href={project.website} target="_blank" rel="noopener noreferrer">Website</a></li>
      </ul>
    </div>
  );
};

export default Project;
